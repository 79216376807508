/*** IMPORTS FROM imports-loader ***/
var define = false;

// import Highcharts from 'highcharts/highmaps';
// import 'highcharts/modules/data';
// import 'highcharts/modules/exporting';
// import 'highcharts/modules/offline-exporting';
// // Alternatively, this is how to load Highstock. Highmaps is similar.
// // import Highcharts from 'highcharts/highstock';
 


export default {
    init: (app) => {

        console.log('Collapse');
        /*
        |
        | Constants
        |------------
        */
        const $sectionGraphList = $('.section-graph-list') ;

        $sectionGraphList.each(function(index) {

            var $section    = $(this);
            var $select     = $section.find('.item-ok');
            var $allGraph   = $section.find('.item-graph');

            $select.on('click',function(){

                var $thisGraph = $(this).data('graph');

                $select.removeClass('is-check');
                $allGraph.removeClass('is-active');

                $(this).addClass('is-check');
                $section.find('.' + $thisGraph ).addClass('is-active');

            });

        });
        $('.highcharts-iframe').each(function(index) {
            $(this)[0].addEventListener('load', function() {
                const iframeBody = this.contentWindow.document.body;
                const height = Math.max(iframeBody.scrollHeight, iframeBody.offsetHeight);
                this.style.height = `${height}px`;
            });

        })

    }
}
