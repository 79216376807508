/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app,ScrollMagic, mobileDetect, Swiper) => {

        /*
		|
		| Constants
		|-----------
		*/
        const 
            $body   = $('body'),
            $header = $('#header')
		;

        

        /*
        |
        | swiper team
        |------------
        */
       var $sectionT = $('.section-team');
        if ($sectionT.length > 0) {

            var _current = false,
                $filter = $sectionT.find('.item-filter'),
                _activeCat  = $sectionT.find('.item-filter.active a').data('value')
            ;


            var SwiperEl = new Swiper($sectionT.find('.swiper-container'), {
                slidesPerView: 1.5,
                spaceBetween: 30,
                navigation: {
                    prevEl: $sectionT.find('.swiper-button-prev'),
                    nextEl: $sectionT.find('.swiper-button-next'),
                },
                breakpoints: {
                    // when window width is >= 320px
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 30
                    }
                }
            });

            var $modal = $('#modal-team').iziModal({
                width: "100%",
                zindex: 99999,
                fullscreen: true,
                overlayColor: 'rgba(0, 0, 0, 0.7)',
                onOpening: function(){
                },
                onClosing: function(){
                   _current.removeClass('is-active');
                },
            });
            $sectionT.find('.card-team-ctr').on("click", function (e) {
                e.preventDefault();
                _current = $modal.find('.'+$(this).attr('href'));
                console.log($(this).attr('href'));
                console.log(_current);
                _current.addClass('is-active')
                $modal.iziModal('open');
            });

            $filter.on('click', function (e) {
                e.preventDefault();
                $filter.removeClass('active');
                $(this).addClass('active');
                _activeCat = $(this).find('a').data('value');
                updateSliderEvents(SwiperEl ,_activeCat );
            });


            updateSliderEvents(SwiperEl,_activeCat);
        }


        function updateSliderEvents(SwiperEl, value){
            $sectionT.find('.swiper-slide').show();
            $sectionT.find(`.swiper-slide:not([data-team="${value}"]`).hide();
            try {
              SwiperEl.update();
              SwiperEl.slideTo(0);
            } catch (error) {
              console.log('swiper error');
              // Expected output: ReferenceError: nonExistentFunction is not defined
              // (Note: the exact output may be browser-dependent)
            }
            
        }





        /*
        |
        | ScrollMagic
        |------------
        */
        const controller  = new ScrollMagic.Controller(),
            $sections = $('.section-nav')
        ;
    
        var _mobil = new mobileDetect(),
            _width = $(window).width()
        ;

        if (_mobil.isMobile() == null && _width >= 992) {

            var $submenu  = $('#nav-resume');

            var scene = new ScrollMagic.Scene({
                'triggerElement': '#nav-resume',
                'triggerHook'   : 0,
                'reverse': true
            })
            .setPin("#nav-resume")
            .addTo(controller)
            .on('enter', function(e){
                $submenu.addClass('is-fixed');
            })
            .on('leave', function(e){
                $submenu.removeClass('is-fixed');
            })
            //.addIndicators()
            ;
        }
        let $itemsSubMenu = $('.list-sub-menu > .item-nav');
        //console.log($sections);
        $sections.each(function(index){

            let _attr       = $(this).attr('id'),
                $this       = $('#'+_attr),
                _heightMar  = $this.outerHeight(true),
                _heightNo   = $this.outerHeight(),
                _duration   = _heightNo + ( _heightMar - _heightNo )*0.52 
            ;

            new ScrollMagic.Scene({
                triggerElement: "#"+_attr,
                duration: _duration
            })
            .on('enter', function(e){
                $itemsSubMenu.removeClass('active');
                $('#nav-'+_attr).addClass('active');
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(controller);

        });

        // Fix bug Header Height
        const $scrollmagicPinSpacer = $('.scrollmagic-pin-spacer'),
              $sectionSubMenu       = $('.section-sub-menu')
        ;

        $scrollmagicPinSpacer.css({
            'height': `${ $header.outerHeight() }px`
        })

        $(document).on('scroll', () => {

            if ( $sectionSubMenu.hasClass('is-fixed') ) {

                $sectionSubMenu.css({
                    "transform": "translateY("+ $header.outerHeight() +"px)"
                })

            } else {

                $sectionSubMenu.css({
                    "transform": "translateY(0)"
                })

            }

        });

        /*
        |
        | Constants
        |------------
        */
        const
            $loadMoreBtn = $('.load-more-btn'),
            $postsContainer = $('.list-press'),
            $loadMoreContainer = $('.load-more-container'),
            $loadMore = $('.load-more'),
            $loaderContainer = $('.loader-container')
        ;
 
        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        $loadMoreBtn.on('click', function(e){

            e.preventDefault();
            
            abort(xhr);

            let offset = $postsContainer.find('.press-item').length;
            let cat = $loadMoreBtn.data('cat');
            let url = `/ajax/posts_2/${cat}/${offset}/`;

            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    TweenMax.to($loadMore, 0.5, {autoAlpha: 0, y:30, display: "none", ease: Power1.easeOut, onComplete: () => {
                        TweenMax.fromTo($loaderContainer, 0.5, {autoAlpha: 0, y:30, display: "none"}, {autoAlpha: 1, y:0, display: "block", ease: Power1.easeOut})
                    }})
                },
                success: (response, status) => {

                    $postsContainer.append(response);

                    TweenMax.to($loaderContainer, 0.5, {autoAlpha: 0, y:50, display: "none", ease: Power1.easeOut});
                    console.log($(response));
                    if($(response).find('.no-more-post').length) {
                        $loadMoreContainer.remove();
                    } else {
                        TweenMax.to($loadMore, 0.5, {autoAlpha: 1, y:0, display: "block", ease: Power1.easeOut})
                    }

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
            
        });

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }



    }
}
