/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
require('@lib/selectric/jquery.selectric.js')($); //désolé
import Collapse from 'bootstrap/js/dist/collapse.js';
import Isotope from "isotope-layout";
import Swiper from 'swiper/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import CustomEase from "@lib/gsap-pro/CustomEase";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";

import imagesLoaded from 'imagesloaded';


import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';


/*
|
| Importing Components
|-----------------------
*/
import MaterializeForm from '@components/materialize-form.js';
import mobileDetect from '@components/mobile-detect';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import Video from '@components/video.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
//import news from '@pages/news.js';
import animations from '@pages/animations.js';
import home from '@pages/home.js';
import archive from '@pages/archive.js';
import repository from '@pages/repository.js';
import single from '@pages/single.js';
import events from '@pages/events.js';
import about from '@pages/about.js';
import media from '@pages/media.js';
import publication from '@pages/publication.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': animations,
        'dependencies': [app, Menu, Kira, ScrollMagic, CustomEase, imagesLoaded]
    },
	{
		'file': main, 
		'dependencies': [app, ScrollTo, Video, Isotope, Collapse, MaterializeForm]
    },
    {
		'file': about, 
        'dependencies': [app, ScrollMagic, mobileDetect, Swiper],
		'resolve': '#page-about'
    },
    {
		'file': media, 
		'dependencies': [app, Swiper ,imagesLoaded],
		'resolve': '#page-media-archive'
    },
    {
		'file': publication, 
		'dependencies': [app, Swiper ,imagesLoaded],
		'resolve': '#page-publication-archive'
    },
    {
		'file': archive, 
		'dependencies': [app],
		'resolve': '#page-news-archive'
    },
    {
		'file': events, 
		'dependencies': [app, imagesLoaded],
		'resolve': '#page-events-archive'
    },
    {
		'file': repository, 
		'dependencies': [app],
		'resolve': '#page-repository-archive'
    },
    {
		'file': single, 
		'dependencies': [app],
		'resolve': '#page-single-news'
    },
	{
		'file': home, 
		'dependencies': [app, Swiper],
		'resolve': '#page-home'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

