/*** IMPORTS FROM imports-loader ***/
var define = false;


export default {
    init: (app, imagesLoaded) => {

        console.log('eventss');
        /*
        |
        | Constants
        |------------
        */
        const   $selectric          = $('.select-form'),
                $inputRadio         = $("input[type=radio]")
        ;
        


        $inputRadio.on('change',function(e){
            e.preventDefault();
            $form.submit();
        });


        var     $press                = $('#press-event'),
                $form                 = $press.find('form.form-ctr'),
                $Pressaddresult       = $press.find('.loaded-posts'),
                $PressctrLoader       = $press.find('.container-loader-btn'),
                $PressbtnloadEvent    = $press.find('.btn-more-event')
        ;

        $PressbtnloadEvent.on('click', function (e) {
            e.preventDefault();
            ajaxLoad('/ajax/events/'+$form.serialize()+'/'+$(this).attr('next-link'),
                $Pressaddresult, $PressctrLoader, $(this)
            );
        });
        /*
        var $myGroup = $('#eventsListGroup');
        $myGroup.on('show.bs.collapse','.collapse', function() {
          console.log($('#eventsListGroup .custom-card[aria-expanded="true"]').length)
           $('#eventsListGroup .custom-card[aria-expanded="true"]').trigger( "click" );//.collapse('hide');
        });
        */
        $(document).on('show.bs.collapse', function(event){
            // console.log( "in! print e: " +event.type, event.target.parentElement, $(event.target.parentElement).offset().top);
            // event.target.parentElement.scrollIntoView();

            $('#eventsListGroup .custom-card[aria-expanded="true"]').trigger( "click" );

            setTimeout(function() {
              $('html, body').stop().animate({
                scrollTop: $(event.target.parentElement).offset().top - 110
              }, 300);
            }, 300)
            
        });

        $(document).on('hide.bs.collapse', function(event){
            // console.log( "in! print e: " +event.type, event.target.parentElement, $, jQuery);

            // $('#benefits').on('shown.bs.collapse', function () {
    
              $('html, body').stop().animate({
                scrollTop: $(event.target.parentElement).offset().top - 110
              }, 300);
/*
            let dims = event.target.parentElement.getBoundingClientRect();
            window.scrollTo(window.scrollX, dims.top - 250 + window.scrollY);
            // event.target.parentElement.scrollIntoView();
            */
        });


         /*
        |
        | Press release
        |------------
        */
        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        function ajaxLoad(url,$addresult, $ctrLoader,$btnloadPost, $empty = false){

            abort(xhr);
            console.log(url);
            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    console.log($empty);
                    if ($empty) {
                        $ctrLoader.removeClass('is-finish');
                        $addresult.find('.custom-card-ctr').hide().remove();
                    }
                    $ctrLoader.addClass('is-loading');
                },
                success: (response, status) => {
                    $ctrLoader.removeClass('is-loading');

                    $(response).appendTo($addresult);
                    let $nextDiv = $addresult.find('.change-nav');
                    if ($nextDiv.length > 0) {
                        $btnloadPost.removeClass('d-none').attr('next-link',$nextDiv.data('next'));
                        $nextDiv.remove();
                    }else{
                        $ctrLoader.addClass('is-finish');
                        $btnloadPost.addClass('no-more');
                    }
                    let $img              = $('.lazy-ctr:not(.is-load) .img-lazy');
                    const preloadImages = new Promise((resolve, reject) => {
            //            imagesLoaded(document.querySelectorAll(".img-lazy"), { background: true }, resolve);
                        var imgLoad = imagesLoaded($img, { background: false }, resolve);
                        imgLoad.on( 'progress', function( instance, image ) {
                            var result = image.isLoaded ? 'loaded' : 'broken';
                            var lazyContainer = image.img.closest(".lazy-ctr");
                            lazyContainer.classList.add("is-load");
                        });
                    });
                    atcb_init();
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(status);
                    console.log(error);
                    console.log(response.responseText);
                }
            });
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
