/*** IMPORTS FROM imports-loader ***/
var define = false;


export default {
    init: (app) => {

        /*
        |
        | Press release
        |------------
        */
        var     $repo              = $('.section-repository-all'),
                $form              = $repo.find("form.form-ctr"),
                $inputRadio        = $repo.find("input[type=radio]")
        ;
     

        $inputRadio.on('change',function(e){
            e.preventDefault();
            $form.submit();
        });


        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        function ajaxLoad(data){

            abort(xhr);

            console.log(data);
            let url = `/ajax/posts/${data[0]['value']}/${data[1]['value']}/${lang}/page/`;

            console.log(url);
            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    console.log('before send');
                    $postsContainer.fadeOut(200, function () {
                        $(this).html('')
                    })
                    $loadMoreContainer.show()
                    $loadingContainer.show()
                },
                success: (response, status) => {
                    console.log(response);
                    $loadingContainer.hide()

                    $postsContainer.fadeIn(200, function () {
                        $(this).html(response).show();
                        TweenMax.to($(this).find('[data-stagger-item]'), 0.5, {autoAlpha: 1});
                    });

                    
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(status);
                    console.log(error);
                    console.log(response.responseText);
                }
            });
            
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
