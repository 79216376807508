/*** IMPORTS FROM imports-loader ***/
var define = false;


export default {
    init: (app, selectric) => {

        /*
        |
        | Constants
        |------------
        */
        const   $selectric          = $('.select-form'),
                $form               = $('form.form-ctr'),
                $inputPage          = $("#change-page"),
                $postsContainer     = $('.loaded-posts'),
                $loadMoreContainer  = $('.load-more-container'),
                $loadingContainer   = $('.loading-container'),
                lang = $('body').data('lang') ? $('body').data('lang') : false
        ;
        $selectric.each(function(){
            var $select  = $(this);
            var _default = $select.val();

            $select.selectric({
                forceRenderBelow:true, 
                preventWindowScroll: true,  
                maxHeight: 350,
                onInit: function (elt) {
                    var $elt = $(elt).parents('.selectric-wrapper');
                    console.log($(elt).val());
                    if ($(elt).val() != 'all') {
                        $elt.addClass('selectric-change');
                    }
                },
                onChange: function (elt) {
                    var $elt = $(elt).parents('.selectric-wrapper');
                    var _val = $(elt).val();

                    if (_val != _default) {
                        console.log('true change');
                        $inputPage.val(1);
                        _default = _val;
                        if (_val != 'all') {
                            $elt.addClass('selectric-change');
                        }else{
                            $elt.removeClass('selectric-change');
                        }

                        var data = $form.submit();
                    }else{
                        console.log('false change');
                    }
                }
            });

        })

    }
}
