/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, imagesLoaded) => {

        /*
        |
        | Press release
        |------------
        */
        var     $press              = $('.section-publication-page'),
                $form               = $('form.filter-publication'),
                $input              = $form.find('input[type=radio]'),
                $search              = $('#search-publications'),
                $searchForm              = $('#search-publications-form'),
                $searchWrapper              = $('#search-wrapper')
        ;
        
        $searchForm.on('submit',function(e){
            e.preventDefault();
            applySearch()
        });
        let tmpEntry = '';
        
        $search.keyup(function(event) { 
          if ($search.val().length > 0) {
            $searchWrapper.addClass('filled')
          } else {
            $searchWrapper.removeClass('filled')
          }
        });

        $searchWrapper.find('.icon').click(function (event) {
          $searchForm.submit()
        });
        
        $searchWrapper.find('.input svg').click(function (event) {
          $search.val('')
          applySearch()
        });

        function applySearch() {
          tmpEntry = $search.val()
          $('.card-actu-ctr.is-publication').each(function (index) {
            var $inSearch = false;
            const val = $search.val();
            if (val) {
              if ($(this).find('.item-content .title').text().toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                $inSearch = true;
              }
              if ($(this).find('.item-content .cms').text().toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                $inSearch = true;
              }
              if ($inSearch) {
                $(this).show();
              } else {
                $(this).hide();
              }
            } else {
              $(this).show();
            }
          });
          if ($search.val().length > 0) {
            $searchWrapper.addClass('filled')
          } else {
            $searchWrapper.removeClass('filled')
          }
        }

        $input.on('change',function(e){
            e.preventDefault();
            $form.submit();
        });

        $press.each(function (index) {
        	let $section = $(this);

        	var $addresult          = $section.find('.loaded-posts'),
	            $ctrLoader          = $section.find('.container-loader-btn'),
	            $btnloadPost       = $section.find('.btn-more-press')
            ;
        	// body...
	        $btnloadPost.on('click', function (e) {
	            e.preventDefault();
	            ajaxLoad($section.attr('data-cat'),$(this).attr('next-link'),$addresult, $ctrLoader, $btnloadPost);
	        });
        });


         /*
        |
        | Press release
        |------------
        */
        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        function ajaxLoad(cat, paged,$addresult, $ctrLoader,$btnloadPost){



            abort(xhr);

            let url = `/ajax/publication/${cat}/${paged}/${$form.find('input[type=radio]:checked').val()}`;

            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                data: 'ajax_lang=' + $('html').data('icl'),
                dataType: 'json',
                beforeSend: () => {
                    $ctrLoader.addClass('is-loading');

                },
                success: (response, status) => {
                    $ctrLoader.removeClass('is-loading');

                    $(response).appendTo($addresult);
                    let $nextDiv = $addresult.find('.change-nav');
                    if ($nextDiv.length > 0) {
                        $btnloadPost.removeClass('d-none').attr('next-link',$nextDiv.data('next'));
                        $nextDiv.remove();
                    }else{
                        $ctrLoader.addClass('is-finish');
                        $btnloadPost.addClass('no-more');
                    }

                    let $img              = $('.lazy-ctr:not(.is-load) .img-lazy');
                    const preloadImages = new Promise((resolve, reject) => {
            //            imagesLoaded(document.querySelectorAll(".img-lazy"), { background: true }, resolve);
                        var imgLoad = imagesLoaded($img, { background: false }, resolve);
                        imgLoad.on( 'progress', function( instance, image ) {
                            var result = image.isLoaded ? 'loaded' : 'broken';
                            var lazyContainer = image.img.closest(".lazy-ctr");
                            lazyContainer.classList.add("is-load");
                        });
                    });

                    xhr = null;

                },
                error: (response, status, error) => {
                    console.log(status);
                    console.log(error);
                    console.log(response.responseText);
                }
            });
            
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
