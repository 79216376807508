/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Menu, Kira, ScrollMagic, CustomEase, imagesLoaded) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body             = $('body'),
            $pageLoader       = $('#page-loader'),
            $header           = $('#header'),
            scrollAreas       = document.querySelectorAll('.scroll-area'),
            $splitItems       = $body.find('[data-splittext]'),
            $menuWrapper      = $('#mobile-menu'),
            $menuButton       = $('#header .btn-menu'),
            $menuBars         = $menuButton.find('.item-burger > span'),
            $menuClose        = $menuWrapper.find('.item-close'),
            $menuStaggerItems = $menuWrapper.find('[data-stagger-item]'),
            $img              = $('.img-lazy'),
            $iframe           = $('iframe[loading=lazy]')
        ;


        $iframe.each(function (index) {
            $(this).attr('data-ot-ignore',"");
            $(this).addClass('optanon-category-C0001');
        });
       /*
       /*
        |
        | PreloadImage
        |----------
        */
        const preloadImages = new Promise((resolve, reject) => {
//            imagesLoaded(document.querySelectorAll(".img-lazy"), { background: true }, resolve);
            var imgLoad = imagesLoaded($img, { background: false }, resolve);

            imgLoad.on( 'progress', function( instance, image ) {
                var result = image.isLoaded ? 'loaded' : 'broken';
                var lazyContainer = image.img.closest(".lazy-ctr");
                lazyContainer.classList.add("is-load");
            });

        });

        

        /*
		|
		| Easings
		|----------
        */
        CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1")
        CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1") 
        CustomEase.create("easeCustomPower1", "M0,0 C0.165,0.84 0.44,1 1,1") 
        CustomEase.create("easeExpo", "M0, 0 C1, 0 0, 1 1, 1")

		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
		});


        /*
		|
		| Loader
		|---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
            
            const loaderTl = new TimelineMax({ paused: true});

            loaderTl.set($pageLoader.find('.item-loadbar-inner'), { delay:0.4, className: "+=go"} )
            loaderTl.to($pageLoader.find('.logo'), 1.2, { autoAlpha: 0, ease: Power1.easeOut },'0')
            loaderTl.addCallback(() => { app.dispachEvent($body, 'loaderEnd'); })
            loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0')

            $(window).on('load', function () {
                loaderTl.play();
            });
        } else {
            $(window).on('load', function(){
                app.dispachEvent($body, 'loaderEnd');
            })
            
        }


        /*
		|
		| Menu Mobil
		|-------
        */
        const menu = new Menu($menuWrapper, $menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
            .set($header,{className:'+=is-mobil-active'})
            .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
			.to($menuWrapper, 0.5, { autoAlpha: 1, ease: Power1.easeOut }, 'start+=0.3')
            .staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, y: 20, ease: Power1.easeOut }, '0.1', '+=0')
        
        menu.init();


        /*
        |
        | Class scroll menu
        |--------------
        */
        var controller   = new ScrollMagic.Controller();
        var sceneMenu     = new ScrollMagic.Scene({
            offset: 200,
            triggerHook: 0
        })
        //.addIndicators()
        .on('enter', function(e){
            console.log('enter')
            $header.addClass('floating slide-down is-blue');
        })
        .on('leave', function(e){
            console.log('leave');
            TweenMax.to($header,0.2,{
                y:'-100%',
                onComplete:function(){
                    $header.removeClass('floating slide-down is-blue');
                    TweenMax.set($header,{y:'0%'});
                }
            })
        })
        .addTo(controller);


        /*
		|
		| Kira
		|-------
        */
        const kira = new Kira(ScrollMagic, {
            debug: false,
            loadEvent: {
                domElement: $body,
                eventName: 'loaderEnd'
            },
            optimize: true,
            options: {
                start: '-=0.6',
                triggerHook: 'onEnter'
            }
        });

       
        /*
        | fadeInUp.parallax.sm
        |-----------------------
        */
        kira.add('fadeInUp.parallax', ($item, timeline, start) => {
            timeline.fromTo($item,  0.6 , { y: 100 }, { y: 0, autoAlpha: 1, ease: Sine.easeOut }, start)
        });
        kira.add('fadeInUp.stagger.xs', ($item, timeline, start) => {
            timeline.staggerFromTo($item.find('[data-stagger-item]'), 0.6, {y: 20}, { autoAlpha: 1, y: 0, ease: Power1.easeOut }, '0.1', start)
        });
        kira.add('fadeInUp.stagger.sm', ($item, timeline, start) => {
            timeline.staggerFromTo($item.find('[data-stagger-item]'), 1, {y: 70}, { autoAlpha: 1, y: 0, ease: Power1.easeOut }, '0.1', start)
        });

    

        $body.on('loaderEnd', () => {
            console.log('loaderEnd');

            /*
            |
            | LazyLoad
            |-----------------
            */
            let allDone = [preloadImages];

            Promise.all(allDone).then(()=>{
                console.log('Toutes les promises sont DONE');
            });


        });



        kira.init();

	}
}
