/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, ScrollTo, Video, Isotope, Collapse, MaterializeForm) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body           = $('body'),
            $map            = $('#map'),
            shareBtn = $('.share-btn').parent().get(0)
		;

        /*
        |
        |  Share button :hover
        |---------------------
        |*/
        $( shareBtn ).hover(

            function() {

              $( this ).addClass( "hovered" );

            }, function() {

              $( this ).removeClass( "hovered" );

            }

        );


        var $modalNews = $('#newsletter-modal').iziModal({
            zindex: 99999,
            fullscreen: true,
            width: 700,
            overlayColor: 'rgba(0, 0, 0, 0.7)'
        });


        /*
        |
        |  Scroll Section
        |-----------------
        |*/
        var $linkbottom = $(".scroll-section");
        $linkbottom.on('click',function(e){
          console.log('scroll-section')
            e.preventDefault();
            let $section = $($(this).attr('href'));
            var _offset = $section.data('offset-top') ? $section.data('offset-top') : 50;
            var elemPos = $section.offset().top - _offset;
            TweenMax.to(window, 0.8, {scrollTo:elemPos,  ease: Power1.easeOut });
        });

        

        $('#active-search').on('click',function(e){
            e.preventDefault();
            $($(this).attr('href')).toggleClass('is-active');
        });


        var $contactForm = $('.block-contact-ctr form');
        if ($contactForm.length > 0) {
            new MaterializeForm($contactForm, {
                selectors: {
                    group: '.gfield',
                    label: '.gfield_label',
                    input: '.ginput_container_text input, .ginput_container_email input, .ginput_container_phone input'
                },
                labelEffect: {
                    duration: 0.3,
                    ease: Power2.easeOut
                }
            });
        }
          
       /*
        |
        | Filter
        |-----------
        */
        var $sectionFilter = $('.section__filter');

        if ($sectionFilter.length > 0 ) {

            $sectionFilter.each(function(index){
                var $nav         = $(this).find('#filter-cat'),
                    $grid        = $(this).find('#grid-filter'),
                    $navItem     = $nav.find('.item-filter'),
                    $itemCurrent = $nav.find('.item-filter.active'),
                    _current     = $itemCurrent.find('a').attr('data-value'),
                    $more        = $(this).find('.moreTeam'),
                    iso
                ;
                if ($more.length > 0) {
                    var _nb = $more.data('more') ? $more.data('more') : 4;
                    var _activeBtn = false; 

                    $navItem.each(function(index){
                        var $link = $(this).find('a');
                        var $item = $('.' + $link.data('value') );
                        if ($item.length > 4) {
                            _activeBtn = true; 
                        }
                        $item.each(function (jndex) {
                            if (jndex >= _nb) {
                                $(this).addClass('d-none');
                            }
                        });
                    });
                    $more.on('click',function(e){
                        e.preventDefault();
                        TweenMax.to($(this).parent(),0.5,{
                            autoAlpha:0, y: 50, 
                            onComplete: function(){
                                $grid.find('.grid-item.d-none').removeClass('d-none');
                                iso.layout();
                                $more.parent().remove();
                            }
                        })

                    });
                    if (_activeBtn == false ) {
                        $more.parent().addClass('d-none');
                    }
                }
                




                iso = new Isotope( $grid[0] , {
                    itemSelector: '.grid-item',
                    layoutMode: 'fitRows',
                    filter: '.'+_current,
                    isFitWidth: true,
                    hiddenStyle: {
                        opacity: 0
                    },
                    visibleStyle: {
                        opacity: 1
                    }
                });

                $navItem.on('click',function(e){
                    e.preventDefault();
                    $navItem.removeClass('active');
                    _current = $(this).find('a').attr('data-value');

                    var _desc = $(this).find('a').attr('data-desc');
                    //$sectionFilter.find('.data-description').removeClass('is-active');

                    if ($(_desc).length < 1) {
                        //$(_desc).addClass('is-active');
                    }
                    iso.arrange({
                        filter: '.'+_current
                    });
                    $(this).addClass('active');
                });

            });

        }


        $body.on('loaderEnd', () => console.log('ended'))
	}
}
