/*** IMPORTS FROM imports-loader ***/
var define = false;


export default {
    init: (app, Swiper, imagesLoaded) => {


        /*
        |
        | Partner
        |------------
        */
        var $partner = $('#swiper-partners');

        var SwiperEl = new Swiper($partner.find('.swiper-container'), {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: true,
            loopAdditionalSlides: 2,
            autoplay: {
                delay: 2000,
            },
             breakpoints: {
                // when window width is >= 320px
                640: {
                  slidesPerView: 6,
                  spaceBetween: 30
                }
            }
        });



        /*
        |
        | Press release
        |------------
        */
        var     $press                = $('#press-release'),
                $Pressaddresult       = $press.find('.loaded-posts'),
                $PressctrLoader       = $press.find('.container-loader-btn'),
                $PressbtnloadPress    = $press.find('.btn-more-press')
        ;

        $PressbtnloadPress.on('click', function (e) {
            e.preventDefault();
            ajaxLoad('/ajax/media/press/'+$(this).attr('next-link'),
                $Pressaddresult, $PressctrLoader, $(this)
            );
        });

        var     $actu                = $('#actu-post'),
                $Actuaddresult       = $actu.find('.loaded-posts'),
                $ActuctrLoader       = $actu.find('.container-loader-btn'),
                $ActubtnloadPress    = $actu.find('.btn-more-press'),
                $inputYears          = $actu.find('input[name=archiveYears]'),
                _currentYears        = $inputYears.val(),
                $inputThematic       = $actu.find('input[name=thematic]'),
                _currentThematic     = $inputThematic.val()
        ;

        $ActubtnloadPress.on('click', function (e) {
            e.preventDefault();
           // ajaxLoad('/ajax/post/'+$(this).attr('next-link')+'/'+$inputYears.val()+'/'+$inputThematic.val(),
            ajaxLoad('/ajax/post/'+$(this).attr('next-link')+'/'+$inputYears.val(),
                $Actuaddresult, $ActuctrLoader, $(this)
            );
        });
            
        $inputYears.on('change',function(e) {
           e.preventDefault();
            _currentYears = $(this).val();
            // ajaxLoad('/ajax/post/1/'+_currentYears+'/'+_currentThematic,
            ajaxLoad('/ajax/post/1/'+_currentYears,
                $Actuaddresult, $ActuctrLoader, $ActubtnloadPress, true
            );
        });

        // $inputThematic.on('change',function(e) {
        //    e.preventDefault();
        //     _currentThematic = $(this).val();
        //     ajaxLoad('/ajax/post/1/'+_currentYears+'/'+_currentThematic,
        //         $Actuaddresult, $ActuctrLoader, $ActubtnloadPress, true
        //     );
        // });






         /*
        |
        | Press release
        |------------
        */
        let xhr = null;

        /*
        |
        | Load more ajax
        |--------------
        */
        function ajaxLoad(url,$addresult, $ctrLoader,$btnloadPost, $empty = false){

            abort(xhr);
            console.log(url);
            xhr = $.ajax({
                url: url,
                type: 'GET',
                method: 'GET',
                dataType: 'json',
                data: 'ajax_lang=' + $('html').data('icl'),
                beforeSend: () => {
                    console.log($empty);
                    if ($empty) {
                        $ctrLoader.removeClass('is-finish');
                        $addresult.find('.custom-card-ctr').hide().remove();
                    }
                    $ctrLoader.addClass('is-loading');
                },
                success: (response, status) => {
                    $ctrLoader.removeClass('is-loading');

                    $(response).appendTo($addresult);
                    let $nextDiv = $addresult.find('.change-nav');
                    if ($nextDiv.length > 0) {
                        $btnloadPost.removeClass('d-none').attr('next-link',$nextDiv.data('next'));
                        $nextDiv.remove();
                    }else{
                        $ctrLoader.addClass('is-finish');
                        $btnloadPost.addClass('no-more');
                    }
                    let $img              = $('.lazy-ctr:not(.is-load) .img-lazy');
                    const preloadImages = new Promise((resolve, reject) => {
            //            imagesLoaded(document.querySelectorAll(".img-lazy"), { background: true }, resolve);
                        var imgLoad = imagesLoaded($img, { background: false }, resolve);
                        imgLoad.on( 'progress', function( instance, image ) {
                            var result = image.isLoaded ? 'loaded' : 'broken';
                            var lazyContainer = image.img.closest(".lazy-ctr");
                            lazyContainer.classList.add("is-load");
                        });
                    });
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(status);
                    console.log(error);
                    console.log(response.responseText);
                }
            });
        }

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}
