/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper ) => {


        /*
        |
        | Slider Partner
        |-----------------
        */
        const $sectionT = $('.section-events');

        if($sectionT.length > 0){
             var SwiperEl = new Swiper($sectionT.find('.swiper-container'), {
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: {
                    prevEl: $sectionT.find('.swiper-button-prev'),
                    nextEl: $sectionT.find('.swiper-button-next'),
                },
                scrollbar: {
                    el: $sectionT.find('.swiper-scrollbar'),
                 },
            });

        }

    }
}















